<template>
  <div>
    <top-nav :showLogo="false" :title="$t('兑换')"></top-nav>

    <div class="container" style="margin-top: 2rem">
      <div class="balance-cont">
        <div class="balance">
          {{ $t('钱包余额') }}: {{ userUdst }} USDT
        </div>

        <div class="input-cont">
          <input type="text" :placeholder="$t('请输入')" v-model="copyUsdt" @focus="promptText =''"/>
          <span>USDT</span>
          <img v-if="copyUsdt" @click="emptyValue" class="empty-icon"
               src="../../assets/image/rwa/empty-icon.png">
        </div>
        <div class="all-but">
          <span @click="allAmount">{{ $t('全部') }}</span>
        </div>
      </div>
      <div class="prompt-cont">
        {{ promptText }}
      </div>
    </div>

    <div class="exchange-icon">
      <img src="../../assets/image/bank/exchange-icon.png">
    </div>

    <div class="total-cont">
      <div class="key">{{ $t('聚合资产') }}</div>
      <div class="value"> {{ copyUsdt }} BUR</div>
    </div>

    <div class="bottom_cont">
      <span v-if="isLoading === false">
        <div class="_but" @click="handleSwap">
          {{ $t('确定') }}
        </div>
      </span>
      <span v-else>
        <div class="_but" style="pointer-events: none;cursor: default;">
          {{ $t('兑换中...') }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {mapState} from "vuex";

export default {
  name: "exchange",
  components: {TopNav},
  data() {
    return {
      account: '', // 用户地址
      userUdst: 0, // 用户usdt数值
      copyUsdt: 0, // 填入的需要兑换的数值
      swapUsdt: 0, // swap授权数量
      promptText: '',
      isLoading: false
    }
  },
  computed: {},

  created() {
    // this.swapUsdt = this.$store.dispatch('bur/getAllowanceSwap', '0xDD8eb2AF7F81A6f05b597A0C0815BAf2eC9A5114')

    // this.userUdst = this.$store.dispatch('bur/getUsdtBalance', '0xDD8eb2AF7F81A6f05b597A0C0815BAf2eC9A5114')

    // 闪兑授权
    // this.$store.dispatch('bur/authorizedTransactionSwap', { account:'0xDD8eb2AF7F81A6f05b597A0C0815BAf2eC9A5114', number:'10'})

    //闪兑bur
    // this.$store.dispatch('bur/usdtSwapBur', { account:'0xDD8eb2AF7F81A6f05b597A0C0815BAf2eC9A5114', number:'100'})
    // console.log('11qqqqqqqqqqqqqqqqq', this.userUdst)
    // this.getUserUsdt()
    // this.getBalanceOf()
    this.init()
  },
  watch: {
    'copyUsdt': function (newData) {
      this.copyUsdt = newData;
    }
  },
  methods: {

    async init() {
      await this.getAccount()
      await this.getUserUsdt()
      await this.getSwapUsdt()
    },
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              console.log('account--------', res);
              this.account = res
            }
          })
    },
    async getUserUsdt() {
      let that = this;
      this.$store.dispatch('bur/getUsdtBalance', that.account)
          .then((res) => {
            this.userUdst = res
            this.copyUsdt = this.userUdst
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    async getSwapUsdt() {
      let that = this;
      this.$store.dispatch('bur/getAllowanceSwap', that.account)
          .then((res) => {
            console.log('getSwapUsdt----', res)
            this.swapUsdt = res
          })

          .finally(() => {
            this.$toast.clear()
          })
    },
    async authorizedTransactionSwap(account, usdt) {
      let that = this;
      that.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('bur/authorizedTransactionSwap', {account: account, number: usdt})
          .then((res) => {
            console.log('authorizedTransactionSwap----', res)
            if (res.status === true) {
              setTimeout(() => {
                that.usdtSwapBur(account, usdt)
              }, 300)
            }
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    usdtSwapBur(account, usdt) {
      let that = this
      that.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('bur/usdtSwapBur', {account: account, number: usdt})
          .then((res) => {
            console.log('usdtSwapBur----', res)
            if (res.status === true) {
              setTimeout(() => {
                that.$toast(that.$t('兑换成功'));
              }, 300)
              this.init()
            }
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.isLoading = false
            this.$toast.clear()
          })
    },
    handleSwap() {
      let that = this;
      // that.isLoading = true
      if (that.copyUsdt.length === 0 || that.copyUsdt === '0') {
        that.promptText = that.$t('请输入正确的数量')
        return
      }

      if (parseFloat(that.copyUsdt) > parseFloat(that.userUdst)) {
        that.copyUsdt = that.userUdst
      }

      if (that.swapUsdt < that.copyUsdt) {
        this.authorizedTransactionSwap(that.account, that.copyUsdt)
      } else {
        this.usdtSwapBur(that.account, that.copyUsdt)
      }

    },
    emptyValue() {
      this.copyUsdt = ''
      this.promptText = ''
    },
    allAmount() {
      this.copyUsdt = this.userUdst
    }
  }

}
</script>

<style scoped lang="less">
.container {
  margin: 0.7rem 4vw 0.7rem 4vw;
  padding: 1.6rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;
}

.balance-cont {
  padding: 1rem;
  background: #101010;
  border-radius: 0.71rem;

  .balance {
    font-size: 0.79rem;
    color: #6E6045;
  }

  .input-cont {
    text-align: right;
    padding-top: 1.5rem;

    input {
      width: 60%;
      text-align: right;
      font-size: 1rem;
      color: #fff;
      border: none;
      background-color: transparent;
    }

    input::-webkit-input-placeholder {
      color: #6E6045;
    }

    span {
      padding-left: 1.5rem;
      font-size: 0.79rem;
      color: #6E6045;
    }


    .empty-icon {
      margin-left: 0.2rem;
      float: right;
      width: 1.29rem;
    }
  }
  .all-but{
    font-size: 0.82rem;
    color: #6E6045;
  }
}

.exchange-icon {
  width: 1.64rem;
  height: 1.64rem;
  background: #B5904E;
  border-radius: 50%;
  margin: 0.72rem auto;

  img {
    width: 100%;
  }
}

.total-cont {
  overflow: hidden;
  margin: 0.7rem 4vw 0.7rem 4vw;
  padding: 1.1rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;


  .key {
    float: left;
    font-size: 1.07rem;
    color: #B5904E;
  }

  .value {
    float: right;
    font-size: 1.07rem;
    font-weight: bold;
    color: #B5904E;
  }
}

.bottom_cont {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 5rem;
  background: #151515;
  padding: 1.1rem 0.9rem;
  box-sizing: border-box;

  ._but {
    height: 2.86rem;
    line-height: 2.86rem;
    color: #000000;
    font-size: 1.14rem;
    text-align: center;
    background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.43rem;
  }
}

.prompt-cont {
  width: 90%;
  margin: auto;
  color: #E64937;
  text-align: right;
  font-size: 0.86rem;
  padding-top: 0.23rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
</style>
